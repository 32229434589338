<template>
  <div>
    <hr class="yellow-rule" />
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <p class="text-h6 text-sm-h4 font-weight-bold">
            {{ $t("submission_completed") }}
          </p>
          <underline />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-caption text-sm-subtitle-1">
          <p v-html="$t('thank_you')"></p>
        </v-col>
      </v-row>
      <v-row class="justify-center mt-4 mt-sm-8">
        <v-col
          cols="8"
          class="d-flex justify-space-around flex-column-reverse flex-sm-row"
        >
          <v-btn
            outlined
            plain
            color="secondary"
            class="mt-6 mt-sm-0 font-weight-light"
            :to="$i18nRoute({ name: 'home' })"
          >
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t("go_back") }}
          </v-btn>
          <v-btn
            rounded
            color="secondary"
            class="font-weight-regular"
            :to="
              $i18nRoute({
                name: 'submitCompensationSelection'
              })
            "
          >
            <v-icon>mdi-plus</v-icon>
            {{ $t("submit_another") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col cols="12">
          <p class="text-sm-h5 text-subtitle-1 font-weight-bold mb-0">
            {{ $t("contact_us_title") }}
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" class="text-caption text-sm-subtitle-1">
          <p class="mb-2">
            {{ $t("contact_us_body") }}
          </p>
          <a href="mailto:hello@opensalary.jp">hello@opensalary.jp</a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<i18n>
{
  "en": {
    "page_title": "Submission Complete",
    "submission_completed": "Submission Complete!",
    "thank_you": "Thank you for submitting! It will appear on the site after we review it.",
    "submit_another": "Submit another",
    "go_back": "Go back to home",
    "contact_us_title": "Contact us",
    "contact_us_body": "If you have any questions or want to fix the data you have provided, please contact us at the email below."
  },
  "ja": {
    "page_title": "提出完了",
    "submission_completed": "年収情報の提出が完了しました",
    "thank_you": "情報をご提供いただきありがとうございます！<br/>OpenSalaryにて情報を確認次第、データが公開されます。<br/>あなたの貢献が日本をよりオープンにすることにつながります。",
    "will_release": "Open Salaryにて情報を確認次第、データが公開されます。",
    "make_japan_open": "あなたの貢献が日本をよりオープンにすることにつながります。",
    "submit_another": "続けて提出する",
    "go_back": "トップページへ戻る",
    "contact_us_title": "送信したデータについてのお問い合わせ",
    "contact_us_body": "送信した年収情報の訂正等は以下のメールアドレスまでご連絡ください。"
  }
}
</i18n>

<script>
import Underline from "components/Underline.vue";

export default {
  components: {
    Underline
  },
  metaInfo() {
    return {
      title: `${this.$t("page_title")}`
    };
  }
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 700px;
}
</style>
